import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, notification, Button, Spin, PageHeader } from 'antd'
import moment from 'moment'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import StatusTag from '../../../components/base/status'
import LoginBg from '../../../images/login-bg.png'
import client from '../../../api/client'
import HeaderLogo from '../../../images/header-logo.png'
import { useMatch } from '@reach/router'
export default function PublicShiptmentStatus(props) {
  const { trackId } = props
  const [trackingData, setTrackingData] = useState()
  const [trackNumber, setTrackNumber] = useState()
  const [isWrongCode, setIsWrongCode] = useState(false)
  const [loading, setLoading] = useState(false)
  const match = useMatch('/shipmentstatus/:id')
  useEffect(() => {
    setLoading(true)
    if (trackId) {
      client
        .get(`/api/orders/ordershipmentstatusbytracknum/${trackId}`)
        .then((res) => {
          if (res.status === 200) {
            setIsWrongCode(false)
            setTrackingData(res.data)
            setTrackNumber(trackId)
          }
        })
        .catch((err) => {
          setIsWrongCode(true)
          notification.destroy()
          notification.warning({
            message: 'Таны оруулсан ачааны код бүртгэлгүй байна',
            duration: 10,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      client
        .get(`/api/orders/ordershipmentstatusbytracknum/${match.id}`)
        .then((res) => {
          if (res.status === 200) {
            setIsWrongCode(false)
            setTrackingData(res.data)
            setTrackNumber(trackId)
          }
        })
        .catch((err) => {
          setIsWrongCode(true)
          notification.destroy()
          notification.warning({
            message: 'Таны оруулсан ачааны код бүртгэлгүй байна',
            duration: 10,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Helmet title="SB Express" defer={false} />
      <Row className="sb-left-section">
        <Col xl={12} md={8} xs={24} className="login-left-aside-container">
          <div style={leftImageStyle} className="login-left-aside"></div>
        </Col>
        <Col xl={12} md={16} xs={24} className="login-right-aside-container">
          <Helmet title="Ачааны мэдээлэл | SB Express" defer={false} />
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <div>
                  <img src={HeaderLogo} alt="" style={{ marginBottom: 30 }} className="login-header-image" />
                  <div className="w-full">
                    <Row gutter={[10, 10]}>
                      <Col xs={24}>
                        <PageHeader title="Ачааны дэлгэрэнгүй мэдээлэл" className="w-100 p-0 mb-2" />
                        <div hidden={isWrongCode}>
                          <div className="d-flex justify-content-between mb-3">
                            <div className="col-6">Ачааны код</div>
                            <div className="col-6">{trackNumber ? trackNumber : '-'}</div>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                            <div className="col-6">Өдөр & Цаг</div>
                            <div className="col-6">Статус</div>
                          </div>
                        </div>
                        <Spin tip="Ачаалалж байна." spinning={loading}>
                          {trackingData
                            ? trackingData?.map((track, idx) => {
                                return (
                                  <div className="d-flex justify-content-between mb-2 align-items-center" key={idx}>
                                    <div className="col-6">{moment(track.createdDate).format('MM/DD/YYYY h:mm A')}</div>
                                    <div className="col-6">
                                      <StatusTag status={track.status} />
                                    </div>
                                  </div>
                                )
                              })
                            : 'Таны оруулсан ачааны код бүртгэлгүй байна'}
                        </Spin>
                      </Col>
                    </Row>
                    <div>
                      <Button
                        type="link"
                        className="sb-arrow-back"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                        <span>Буцах</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

const leftImageStyle = {
  backgroundImage: `url(${LoginBg})`,
  height: '100vh',
  backgroundSize: 'cover',
}
